import { Link, Outlet } from 'react-router-dom';
import './css/main.css';
import './css/mobile.css';
import App from '../App.js';

function Navbar() {
    return(
        <>
        <div className="navbar-main">
            <span className="logo"><Link to="/" className="logo-link">My App</Link></span>
            <div className="navbar-links">
                {/* <a href="#home">Home</a>
                <a href="#services">Our Services</a>
                <a href="#about">About Us</a>
                <a href="#contact">Contact Us</a> */}
                <Link to="/">Home</Link>
                <Link to="register">Register</Link>
                <Link to="login">Login</Link>
            </div>
        </div>
        <Outlet />
        </>
        );
}

export default Navbar;
import './css/main.css';
import './css/mobile.css';
import Footer from './Footer.js';
import { useLocation } from 'react-router-dom';

function PageNotFound() {
    
    const location = useLocation().pathname.substr(1);
    
    return(
    <>
        <div className="content-container">
            <h1>404</h1>
            <p>The page with url : {location.toLowerCase()} is not found.</p>
        </div>
        <Footer />
    </>
        );
}

export default PageNotFound;
import {Link} from 'react-router-dom';
import './css/main.css';
import './css/mobile.css';

function dageCO() {
    window.open('https://www.dageco.in', '_blank');
}

function Footer() {
    
    return(
        <div className="footer-main">
            <span className="logo"><Link to="/" className="logo-link">My App</Link></span>
            <div className="footer-links">
                <p onClick={dageCO}>Designed & Developed by DageCO Technologies</p>
            </div>
        </div>
        );
}

export default Footer;
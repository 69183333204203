import './css/main.css';
import './css/mobile.css';
import Footer from './Footer.js';

function Login() {
    
    const alertThem = () => {alert("Yes!!! Developed by him.");}
    
    return(
    <>
        <div className="content-container">
            <form action="https://express.dageco.in/login" method="post" autocomplete="off">
                <h1>Login</h1>
                <input type="text" name="user" placeholder="Username" required /><br />
                <input type="password" name="pass" placeholder="Password" required /><br />
                <input type="submit" value="Login" />
            </form>
        </div>
        <Footer />
    </>
        );
}

export default Login;
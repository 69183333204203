import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './MyComponents/Navbar.js';
import Main from './MyComponents/Main.js';
import Footer from './MyComponents/Footer.js';
import Links from './MyComponents/Links.js';
import './App.css';

function App() {
  return (
    <>
        <Links />
    </>
  );
}

export default App;
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar.js';
import Main from './Main.js';
import Register from './Register.js';
import Login from './Login.js';
import PageNotFound from './PageNotFound.js';

export default function Links() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navbar />}>
                    <Route index element={<Main author="Aryan Jangid"/>} />
                    <Route path="register" element={<Register />} />
                    <Route path="login" element={<Login />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
        );
}
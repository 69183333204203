import './css/main.css';
import './css/mobile.css';
import Footer from './Footer.js';

function Main(props) {
    
    const alertThem = () => {alert("Yes!!! Developed by him.");}
    
    return(
    <>
        <div className="content-container">
            <h1>Learning React</h1>
            <p>This Web App is created using React while learning by ,none other than, <b onClick={alertThem}>{props.author}</b>.</p>
        </div>
        <Footer />
    </>
        );
}

export default Main;
import './css/main.css';
import './css/mobile.css';
import Footer from './Footer.js';

function Register() {
    
    const alertThem = () => {alert("Yes!!! Developed by him.");}
    
    return(
    <>
        <div className="content-container">
            <form method="post" action="https://express.dageco.in/register" autocomplete="off">
                <h1>Register</h1>
                <input type="text" name="firstName" placeholder="First Name" required /><br />
                <input type="text" name="lastName" placeholder="Last Name" required /><br />
                <input type="text" name="username" placeholder="Username" required /><br />
                <input type="email" name="email" placeholder="Email" required /><br />
                <input type="password" name="password" placeholder="Password" required /><br />
                <input type="submit" value="Register" />
            </form>
        </div>
        <Footer />
    </>
        );
}

export default Register;